.DropdownMenu {
    width: 100%;
    margin: auto;
    display: block;
    transition: all 0.5s ease-out;
    text-align: center;
    background-color: #ececec;
    cursor: pointer;
    overflow: hidden;
}

.DropdownMenu li {
    border-bottom: 1px solid black;
    padding: 10px;
    margin-bottom: 0;
}

@media (min-width: 500px) {
    .DropdownMenu {
        display: none;
    }
}

.Open {
    max-height: 200px;
}

.Close {
    max-height: 0;
}
