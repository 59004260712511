.DropdownToggle {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    align-items: center;
    padding: 10px 0;
    box-sizing: border-box;
    cursor: pointer;
    background-color: #476650;
}

.DropdownToggle div {
    width: 20%;
    height: 3px;
    background-color: #ccc;
}

@media (min-width: 500px) {
    .DropdownToggle {
        display: none;
    }
}
