.App {
    margin: auto;
    padding: 0 20px;
}

@media (min-width: 500px) {
    .App {
        width: 80%;
    }
}

.Header {
    text-align: center;
}

.Header h1 {
    font-family: 'Playfair Display', serif;
    font-size: 3em;
    margin-bottom: 20px;
    color: black;
    display: inline-block;
}

.Header a {
    text-decoration: none;
}

@media (min-width: 500px) {
    .Header h1 {
        font-size: 4em;
    }
}

@media (min-width: 500px) {
    .Content {
        margin-top: 50px;
    }
}
