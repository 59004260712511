.Menu {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding: 0 20px; */
    box-sizing: border-box;
    /* z-index: 90; */
    /* margin: auto; */
}

@media (min-width: 500px) {
    .Menu {
        max-height: 200px;
    }
}

@media (max-width: 499px) {
    .DesktopOnly {
        display: none;
    }
}
