.ImageModal {
    position: fixed;
    z-index: 500;
    border: 10px solid white;
    box-shadow: 1px 1px 1px #000000;
    transition: all 0.3s ease-out;
    text-align: center;
}

.Caption {
    color: white;
    width: 100%;
    font-weight: 500;
    padding-top: 16px;
}

.ImageModal img {
    max-height: 100%;
    max-width: 100%;
}

.landscape {
    width: 90vh;
    height: 67.5vh;
    left: calc(50% - 45vh - 11px);
    top: calc(50% - 33.75vh - 11px);
}

.square {
    width: 80vh;
    height: 80vh;
    left: calc(50% - 40vh - 11px);
    top: calc(50% - 40vh - 11px);
}

@media (orientation: portrait) {
    /* landscape = limited by width */
    .landscape {
        width: 90vw;
        height: 67.5vw;
        left: calc(50% - 45vw - 11px);
        top: calc(50% - 33.75vw - 11px);
    }

    .square {
        width: 80vw;
        height: 80vw;
        left: calc(50% - 40vw - 11px);
        top: calc(50% - 40vw - 11px);
    }
}

@media (min-width: 500px) {
    .Caption {
        font-size: 1.5em;
    }
}
