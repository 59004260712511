.ImagePreviews {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 20px;
    margin: auto;
    padding: 10px;
}

@media (min-width: 1000px) {
    .ImagePreviews {
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 20vw);
    }
}
