.Bio {
    display: block;
    margin: 20px;
    text-align: center;
}

.Bio img {
    width: 200px;
    border-radius: 30px;
}

.Bio a {
    color: blue;
    text-decoration: none;
}

@media (min-width: 500px) {
    .Bio img {
        width: 300px;
    }
}
