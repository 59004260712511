.NavigationItem {
    margin: 10px 0;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
}

.NavigationItem a {
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display: block;
    font-size: 1.5em;
    color: black;
}

.NavigationItem a:hover,
.NavigationItem a.active {
    color: #649568;
}

@media (min-width: 500px) {
    .NavigationItem {
        margin: 0;
        display: flex;
        height: 100%;
        width: auto;
    }

    .NavigationItem a {
        height: 100%;
        padding: 16px 30px;
        /* border-bottom: 4px solid transparent; */
    }
}
